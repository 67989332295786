import React, {Suspense} from 'react';
import {Spin} from 'antd';
import Text from 'antd/es/typography/Text';

const suspenseHoc = (WrapComponent) => {
  function HocContent() {
    return (
      <Suspense
        fallback={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <Spin size="large" />
            <Text style={{marginTop: 8}}>Loading, Please Wait</Text>
          </div>
        }
      >
        <WrapComponent />
      </Suspense>
    );
  }
  return HocContent;
};

export default suspenseHoc;
