import {API_URL, RIDER_API_URL} from '../../../utils/constants';
import * as actionTypes from '../../actionTypes';
import {http} from '../../http';

export const getRiders = () => {
  return (dispatch) => {
    dispatch({type: actionTypes.RIDER_LOADING, payload: true});
    http
      .get(`${RIDER_API_URL}/riders`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.INIT_RIDERS,
          payload: res.data,
        });
        await dispatch({type: actionTypes.RIDER_LOADING, payload: false});
      })
      .catch(() => {
        dispatch({type: actionTypes.RIDER_LOADING, payload: false});
      });
  };
};


export const addNewRider = (data, successCB) => {
  return (dispatch) => {
    dispatch({type: actionTypes.RIDER_LOADING, payload: true});
    http
      .post(`${RIDER_API_URL}/riders`, data)
      .then(async (res) => {
        if(successCB) {
          successCB();
        }
        dispatch(getRiders());
        dispatch({type: actionTypes.RIDER_LOADING, payload: false});
      })
      .catch((err) => {
        dispatch({type: actionTypes.RIDER_LOADING, payload: false});
      });
  };
};

export const updateRider = (data,successCB) => {
  return (dispatch) => {
    dispatch({type: actionTypes.RIDER_LOADING, payload: true});
    http
      .put(`${RIDER_API_URL}/riders`, data)
      .then(async (res) => {
        if(successCB) {
          successCB();
        }
        dispatch(getRiders());
        dispatch({type: actionTypes.RIDER_LOADING, payload: false});
      })
      .catch((err) => {
        dispatch({type: actionTypes.RIDER_LOADING, payload: false});
      });
  }
}

export const deleteRider = (id) => {
  return (dispatch) => {
    dispatch({type: actionTypes.RIDER_LOADING, payload: true});
    http
      .delete(`${RIDER_API_URL}/riders/${id}`)
      .then(async (res) => {
        dispatch(getRiders());
        dispatch({type: actionTypes.RIDER_LOADING, payload: false});
      })
      .catch((err) => {
        dispatch({type: actionTypes.RIDER_LOADING, payload: false});
      });
  }
}

export const getRiderReport = (from, to) => {
  return (dispatch) => {
    dispatch({type: actionTypes.RIDER_LOADING, payload: true});
    http
      .get(`${API_URL}/wtf_emp/riders/info?from=${from}&to=${to}`)
      .then(async (res) => {
        dispatch({type: actionTypes.INIT_RIDER_REPORT, payload: res.data});
        dispatch({type: actionTypes.RIDER_LOADING, payload: false});
      })
      .catch((err) => {
        dispatch({type: actionTypes.RIDER_LOADING, payload: false});
      });
  }
}