import * as actionTypes from '../../actionTypes';

const initialState = {
  apiLoading: false,
  loginErr: false,
  auth: {},
  userData: {},
};

export default function authReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOGIN_ERROR:
      return {
        ...state,
        loginErr: action.payload,
      };
    case actionTypes.USER:
      return {
        ...state,
        userData: action.payload,
      };
    case actionTypes.AUTH_DATA:
      return {
        ...state,
        auth: action.payload,
      };
    case actionTypes.API_LOADING:
      return {
        ...state,
        apiLoading: action.payload,
      };
    case actionTypes.LOG_OUT:
      window.localStorage.removeItem('auth_token');
      window.localStorage.removeItem('refresh_token');
      return {
        ...state,
        adminData: {},
      };
    default:
      return state;
  }
}
