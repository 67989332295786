import * as actionTypes from '../../actionTypes';

const initialState = {
  cities: [],
  storeList: []
};

export default function storeReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.INIT_STORES:
      return {
        ...state,
        storeList: action.payload,
      };
    case actionTypes.INIT_CITIES:
      return {
        ...state,
        cities: action.payload,
      };
    case actionTypes.LOG_OUT:
      return {
        cities: [],
        storeList: []
      }
    default:
      return state;
  }
}
