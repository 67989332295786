import * as actionTypes from '../../actionTypes';

const initialState = {
  paymentsList: [],
  paymentsError: {},
  paymentsPending: false
};

export default function paymentReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.PAYMENT_DATA_LOADING:
      return {
        ...state,
        paymentsPending: true,
      };
    case actionTypes.PAYMENT_DATA_SUCCESS:
      return {
        ...state,
        paymentsList: action.payload,
        paymentsPending: false,
      };
    case actionTypes.PAYMENT_DATA_FAILED:
      return {
        ...state,
        paymentsError: action.payload,
        paymentsPending: false,
      };
    default:
      return state;
  }
}
