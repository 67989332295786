import React, {lazy, useState} from 'react';
import {Divider, Layout, Menu} from 'antd';
import {BankOutlined, HomeOutlined, LogoutOutlined, ShopOutlined,
  UserOutlined, ShoppingCartOutlined, RocketOutlined, AuditOutlined} from '@ant-design/icons';
import './App.less';
import {useLocation} from 'react-router-dom';

import {Switch} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {push} from 'connected-react-router';
import Text from 'antd/es/typography/Text';
import PrivateAuthHoc from './hoc/PrivateAuthHoc';
import suspenseHoc from './hoc/suspenseHoc';
import LandingAuthHoc from './hoc/LandingAuthHoc';
import AdminAuthHoc from './hoc/AdminAuthHoc';
import PayoutAuthHoc from './hoc/PayoutAuthHoc';
// import DeliveryAuthHoc from './hoc/DeliveryAuthHoc';
import OrderAuthHoc from './hoc/OrderAuthHoc';
import SalesAuthHoc from './hoc/SalesAuthHoc';
import RiderReportPage from './pages/RiderReportPage';

const {SubMenu} = Menu;
const {Header, Content, Sider} = Layout;

const OverviewPage = lazy(() => import('./pages/OverviewPage'));
const AuthPage = lazy(() => import('./pages/AuthPage'));
const LiveOrdersPage = lazy(() => import('./pages/LiveOrdersPage'));
const ManageEmployeesPage = lazy(() => import('./pages/ManageEmployeesPage'));
// const DeliveryManagementPage = lazy(() =>
//   import('./pages/DeliveryManagementPage')
// );
const ManageStores = lazy(() => import('./pages/ManageStores'));
const PayoutPage = lazy(() => import('./pages/PayoutPage'));
const HomePage = lazy(() => import('./pages/HomePage'));
const RiderPage = lazy(() => import('./pages/RiderPage'));

const App = () => {
  const dispatch = useDispatch();
  let location = useLocation();
  const [collapse, setCollapse] = useState(true)

  const isLoggedIn = !!useSelector(
    (state) => state.auth.auth && state.auth.auth.token
  );

  const routeMatch = [
    '/overview',
    '/live-orders',
    '/payouts',
    '/manage-employees',
    // '/delivery',
    '/manage-stores',
    '/rider',
    '/rider-report'
  ];

  const roles = useSelector(
    (state) => state.auth.userData && state.auth.userData.role
  );

  const handleClick = (e) => {
    dispatch(push(routeMatch[parseInt(e.key) - 1]));
  };

  return (
    <>
      <Layout>
        <Header
          className="header"
          style={{position: 'sticky', top: 0, zIndex: 2, display: 'flex'}}
        >
          <div className="logo" />
          <Text
            level={3}
            style={{
              color: '#FFFFFF',
              fontSize: 18,
              cursor: 'pointer',
              userSelect: 'none',
              flexGrow: 1,
            }}
            onClick={() => dispatch(push('/'))}
          >
            WTF Admin Dashboard
          </Text>
        </Header>
        <Layout>
          <Sider
            width={isLoggedIn ? 250 : 0}
            style={{
              overflow: 'auto',
              height: '100vh',
              position: 'fixed',
              left: 0,
              display: isLoggedIn ? 'block' : 'none',
            }}
            collapsible collapsed={collapse} onCollapse={() => setCollapse(!collapse)}
            className="site-layout-background"
          >
            <Menu
              mode="inline"
              onClick={handleClick}
              theme="dark"
              inlineCollapsed={collapse}
              selectedKeys={[
                (routeMatch.indexOf(location.pathname) + 1).toString(),
              ]}
              defaultSelectedKeys={[
                (routeMatch.indexOf(location.pathname) + 1).toString(),
              ]}
              // defaultOpenKeys={['home-submenu']}
              style={{height: '100%', borderRight: 0}}
            >
              {roles && roles.includes('admin') ? (
                <SubMenu
                  key="home-submenu"
                  icon={<HomeOutlined />}
                  title="Home"
                >
                  <Menu.Item key="1">Overview</Menu.Item>
                  {/* <Menu.Item key="2">Live Orders</Menu.Item> */}
                  {/* <Menu.Item key="3">Banners</Menu.Item> */}
                </SubMenu>
              ) : (
                <></>
              )}
              {roles && roles.includes('payout') ? (
                <Menu.Item icon={<BankOutlined />} key="3">
                  Payout
                </Menu.Item>
              ) : (
                <></>
              )}
              {roles && (roles.includes('delivery') || roles.includes('admin')) ? (
                <Menu.Item icon={<ShoppingCartOutlined />} key="2">
                  Live Orders
                </Menu.Item>
              ) : (
                <></>
              )}
              {roles && roles.includes('admin') ? (
                <Menu.Item icon={<UserOutlined />} key="4">
                  Manage Employees
                </Menu.Item>
              ) : (
                <></>
              )}
              {/* {roles && (roles.includes('delivery') || roles.includes('admin')) ? (
                <Menu.Item icon={<ShoppingOutlined />} key="5">
                  Delivery Status
                </Menu.Item>
              ) : (
                <></>
              )} */}
              {roles && roles.includes('sales') ? (
                <Menu.Item icon={<ShopOutlined />} key="5">
                  Manage Stores
                </Menu.Item>
              ) : (
                <></>
              )}
              {roles && roles.includes('admin') ? (
                <Menu.Item icon={<RocketOutlined />} key="6">
                  Rider
                </Menu.Item>
              ) : (
                <></>
              )}
              {roles && roles.includes('admin') ? (
                <Menu.Item icon={<AuditOutlined />} key="7">
                  Rider Report
                </Menu.Item>
              ) : (
                <></>
              )}
              <Divider />
              <Menu.Item
                onClick={() =>
                  dispatch({
                    type: 'RESET'
                  })
                }
                danger
                icon={<LogoutOutlined />}
              >
                Logout
              </Menu.Item>
            </Menu>
          </Sider>
          <Layout className="site-layout">
            <Content
              className="site-layout-background"
              style={{
                margin: 0,
                minHeight: 'calc(100vh - 112px)',
                marginLeft: isLoggedIn ? collapse ? 92 : 262 : 0,
                marginTop: 12,
                marginRight: 12,
                marginBottom: 12,
              }}
            >
              <Switch>
                <LandingAuthHoc
                  exact
                  path="/"
                  component={suspenseHoc(AuthPage)}
                />
                <PrivateAuthHoc
                  exact
                  path="/home"
                  component={suspenseHoc(HomePage)}
                />
                <AdminAuthHoc
                  exact
                  path="/overview"
                  component={suspenseHoc(OverviewPage)}
                />
                <OrderAuthHoc
                  exact
                  path="/live-orders"
                  component={suspenseHoc(LiveOrdersPage)}
                />
                <PayoutAuthHoc
                  exact
                  path="/payouts"
                  component={suspenseHoc(PayoutPage)}
                />
                <AdminAuthHoc
                  exact
                  path="/manage-employees"
                  component={suspenseHoc(ManageEmployeesPage)}
                />
                {/* <DeliveryAuthHoc
                  exact
                  path="/delivery"
                  component={suspenseHoc(DeliveryManagementPage)}
                /> */}
                <SalesAuthHoc
                  exact
                  path="/manage-stores"
                  component={suspenseHoc(ManageStores)}
                />
                <AdminAuthHoc
                  exact
                  path="/rider"
                  component={suspenseHoc(RiderPage)}
                />
                <AdminAuthHoc
                  exact
                  path="/rider-report"
                  component={suspenseHoc(RiderReportPage)}
                />
              </Switch>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default App;
