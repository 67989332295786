import * as actionTypes from '../../actionTypes';

const initialState = {
  employeeData: [],
  employeeLoading: false,
  employeeError: {}
};

export default function employeeReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.EMPLOYEE_DATA_LOADING:
      return {
        ...state,
        employeeLoading: action.payload,
      };
    case actionTypes.EMPLOYEE_DATA_SUCCESS:
      return {
        ...state,
        employeeData: action.payload,
        employeeLoading: false,
      };
    case actionTypes.EMPLOYEE_DATA_FAILED:
      return {
        ...state,
        employeeError: action.payload,
      };
    default:
      return state;
  }
}
