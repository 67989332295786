import * as actionTypes from '../../actionTypes';

const initialState = {
  riders: [],
  riderLoading: false,
  riderReport: [],
}

export default function riderReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.INIT_RIDERS:
      return {
        ...state,
        riders: action.payload,
      };
    case actionTypes.RIDER_LOADING:
      return {
        ...state,
        riderLoading: action.payload
      }
    case actionTypes.INIT_RIDER_REPORT:
      return {
        ...state,
        riderReport: action.payload
      }
    default:
      return state;
  }
}
