import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {useSelector} from 'react-redux';

function AdminAuthHoc({component: Component, ...rest}) {
  const userData = useSelector((state) => state.auth.auth);
  const isAdmin = useSelector(
    (state) =>
      state.auth.userData &&
      state.auth.userData.role &&
      state.auth.userData.role.includes('admin')
  );
  const token = userData.token ? userData.token : '';
  return (
    <Route
      {...rest}
      render={(prop) =>
        token !== '' && isAdmin ? (
          <Component {...prop} />
        ) : (
          <Redirect to={'/home'} />
        )
      }
    />
  );
}

export default AdminAuthHoc;
