import * as actionTypes from '../../actionTypes';

const initialState = {
  stats: {},
  statsError: {},
  statsPending: false,
  restaurants: [],
  restaurantsError: {},
  restaurantsPending: false,
  orders: [],
  ordersError: {},
  ordersPending: false,
  statsView: 'today',
  storeWallets: [],
};

export default function statsReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.STATS_VIEW:
      return {
        ...state,
        statsView: action.payload,
      };
    case actionTypes.STATS_DATA_LOADING:
      return {
        ...state,
        statsPending: true,
      };
    case actionTypes.STATS_DATA_SUCCESS:
      return {
        ...state,
        stats: action.payload,
        statsPending: false,
      };
    case actionTypes.STATS_DATA_FAILED:
      return {
        ...state,
        statsError: action.payload,
        statsPending: false,
      };

    case actionTypes.REST_DATA_LOADING:
      return {
        ...state,
        restaurantsPending: true,
      };
    case actionTypes.REST_DATA_SUCCESS:
      return {
        ...state,
        restaurants: action.payload,
        restaurantsPending: false,
      };
    case actionTypes.REST_DATA_FAILED:
      return {
        ...state,
        restaurantsError: action.payload,
        restaurantsPending: false,
      };

    case actionTypes.ORDERS_DATA_LOADING:
      return {
        ...state,
        ordersPending: true,
      };
    case actionTypes.ORDERS_DATA_SUCCESS:
      return {
        ...state,
        orders: action.payload,
        ordersPending: false,
      };
    case actionTypes.ORDERS_DATA_FAILED:
      return {
        ...state,
        ordersError: action.payload,
        ordersPending: false,
      };
    case 'SET_WALLET_MESSAGE_TRANSACTIONS':
      return {
        ...state,
        storeWallets: action.payload
      }
    default:
      return state;
  }
}
