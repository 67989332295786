import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';

import authReducers from './authReducers';
import employeeReducers from './employeeReducers';
import miscReducers from './miscReducers';
import paymentReducers from './paymentsReducers';
import deliveryReducers from './deliveryReducers';
import statsReducers from './statsReducers';
import storeReducers from './storeReducers';
import riderReducers from './riderReducers';

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducers,
    employees: employeeReducers,
    misc: miscReducers,
    payout: paymentReducers,
    delivery: deliveryReducers,
    stats: statsReducers,
    stores: storeReducers,
    rider: riderReducers,
  });

const rootReducer = (history) => (state, action) => {
  if (action.type === 'RESET') {
    state = undefined;
  }

  return reducers(history)(state, action);
};

export default rootReducer;
