import * as actionTypes from '../../actionTypes';

const initialState = {
  loading: false
};

export default function miscReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
}
