import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {useSelector} from 'react-redux';

function OrderAuthHoc({component: Component, ...rest}) {
  const userData = useSelector((state) => state.auth.auth);
  const token = userData.token ? userData.token : '';
  const isDelivery = useSelector(
    (state) =>
      state.auth.userData &&
      state.auth.userData.role &&
      (state.auth.userData.role.includes('wooptap')
      || state.auth.userData.role.includes('admin')
      )
  );

  return (
    <Route
      {...rest}
      render={(prop) =>
        token !== '' && isDelivery ? (
          <Component {...prop} />
        ) : (
          <Redirect to={'/home'} />
        )
      }
    />
  );
}

export default OrderAuthHoc;
