import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRiderReport } from '../../services/actions/riderActions';
import {Card, Table, Typography, Spin, Button, Modal} from 'antd';
import { DatePicker } from 'antd';
import moment from 'moment';
import { http } from '../../services/http';
import { API_URL } from '../../utils/constants';

const { RangePicker } = DatePicker;
const {Title} = Typography;
function RiderReportPage(){
  const dispatch = useDispatch();
  const riderLoading = useSelector((state) => state.rider.riderLoading);
  const riderReport = useSelector((state) => state.rider.riderReport);
  const [date, setDate] = useState([moment(), moment()]);
  const [open, setOpen] = useState({});
  const [orders, setOrders] = useState([])

  const handleClose = () => {
    setOpen({});
    setOrders([])
  }

  const handleOpen = (rider) => {
    setOpen(rider);
    http.post(`${API_URL}/wtf_emp/rider/orders`,{
      name: rider.name,
      phone: rider.phone,
      from: moment(date[0]).startOf('day').utc().unix(),
      to: moment(date[1]).endOf('day').utc().unix()
    })
    .then((res) => setOrders(res.data))
    .catch((e) => {
      console.error(e);
      setOrders([])
    })
  }

  useEffect(() => {
    dispatch(getRiderReport(moment(date[0]).startOf('day').utc().unix(), moment(date[1]).endOf('day').utc().unix()))
  },[dispatch, date]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Phone',
      key: 'phone',
      align: 'left',
      render: text => <Button type="link" onClick={() => handleOpen(text)} >{text.phone}</Button>,
    },
    {
      title: 'Count',
      dataIndex: 'count',
      key: 'count',
    },
    {
      title: 'Distance',
      dataIndex: 'distance',
      key: 'distance',
    },
    {
      title: 'Collected Amount',
      dataIndex: 'collectedAmount',
      key: 'collectedAmount',
    },
  ];

  const orderColumns = [
    {
      title: 'Order No.',
      dataIndex: 'order_id',
      key: 'order_number',
      render: order => <span>{order.order_number}</span>
    },
    {
      title: 'Date and Time',
      dataIndex: 'created_at',
      key: 'created_at',
      render: data => <div>{moment(data).format('DD-MM-YYYY, hh:mm a')}</div>
    },
    {
      title: 'Distance',
      dataIndex: 'distance',
      key: 'distance',
      render: data => <div>{data ? `${data/1000} km` : 0}</div>
    },
    {
      title: 'State',
      dataIndex: 'events',
      key: 'state',
      render: events => <span>{events[events.length - 1].state}</span>
    },
    {
      title: 'Delivery charge',
      dataIndex: 'order_id',
      key: 'delivery_charge',
      render: order => <div>₹ {order.bill && order.bill.charges && order.bill.charges.map((obj) => obj.name === 'Delivery Charges' ? obj.amount : '')}</div>
    },
    {
      title: 'Bill Total',
      dataIndex: 'order_id',
      key: 'bill_total',
      render: order => <span>₹ {order.bill && order.bill.round_off_total_amount}</span>
    },
  ];

  return (
    <Spin spinning={riderLoading} size={'large'}>
      <Card bordered={false}>
        <Title style={{marginBottom: 4}} level={3}>
          Riders
        </Title>
        <div style={{textAlign: 'right', paddingBottom: 16, paddingTop: 8}}>
        <RangePicker
          defaultValue={[moment(date[0], 'DD/MM/YYYY'), moment(date[1], 'DD/MM/YYYY')]}
          format={'DD/MM/YYYY'}
          onChange={val => setDate(val)}
        />
        </div>
        <Table
          columns={columns}
          dataSource={riderReport}
          size="small"
          style={{width: '100%'}}
        />
      </Card>
      <Modal
        title="Order details"
        visible={open && open.phone}
        onCancel={handleClose}
      >
        <p>Name : {open.name}</p>
        <p>Phone : {open.phone}</p>
        <p>Order Count: {open.count}</p>
        <Table
          columns={orderColumns}
          dataSource={orders}
          size="small"
          style={{width: '100%'}}
        />
      </Modal>
    </Spin>
  )
}

export default RiderReportPage;