export const STATS_DATA_LOADING = 'STATS_DATA_LOADING';
export const STATS_DATA_SUCCESS = 'STATS_DATA_SUCCESS';
export const STATS_DATA_FAILED  = 'STATS_DATA_FAILED';

export const REST_DATA_LOADING = 'REST_DATA_LOADING';
export const REST_DATA_SUCCESS = 'REST_DATA_SUCCESS';
export const REST_DATA_FAILED  = 'REST_DATA_FAILED';

export const ORDERS_DATA_LOADING = 'ORDERS_DATA_LOADING';
export const ORDERS_DATA_SUCCESS = 'ORDERS_DATA_SUCCESS';
export const ORDERS_DATA_FAILED  = 'ORDERS_DATA_FAILED';

export const STATS_VIEW = 'STATS_VIEW';
